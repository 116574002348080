.DateRange {
  display: flex;
  user-select: none;
}

.DateRange__block {
  display: flex;
  align-items: center;
}

.DateRange__block:first-child {
  margin-right: 16px;
}

.DateRange__label {
  font-size: 13.3px;
  margin-right: 5px;
  text-shadow: 0 0 3px white, 0 0 3px white, 0 0 5px white, 0 0 5px white;
}
