.Map {
  position: relative;
  height: 100%;
  width: 100%;
}

.Map__logo {
  position: absolute;
  bottom: 22px;
  right: 30px;
  height: 50px;
  z-index: 450;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-control-coordinates {
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.7);
  padding: 0 12px;
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  border-radius: 2px;
}

.leaflet-control-coordinates-block {
  font-size: 14px;
  min-width: 140px;
  margin-right: 16px;
  line-height: 1;
}

.leaflet-control-coordinates-block span {
  font-family: monospace;
}
