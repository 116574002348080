.PlayerControl {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 500;
  max-width: 1000px;
  background-color: rgba(255, 255, 255, 0.65);
  border: solid 1px rgba(155, 155, 155, 0.5);
  border-radius: 4px;
}

.PlayerControl__loader {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 500;
}

.PlayerControl__control {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 5px;
}

.PlayerControl__footer {
  font-size: 13.3px;
  user-select: none;
}

.PlayerControl__stamps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3px 5px;
  text-shadow: 0 0 3px white, 0 0 3px white, 0 0 5px white, 0 0 5px white;
}

.PlayerControl__switch label {
  position: relative;
  top: -3px;
  font-size: 13px;
  /*margin-right: 12px;*/
  text-shadow: 0 0 3px white, 0 0 3px white, 0 0 5px white, 0 0 5px white;
}

.PlayerControl__switch input {
  height: 14px;
  width: 14px;
}

