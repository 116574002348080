.SpeedLegend__list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  margin: 0;
  padding: 0;
}

.SpeedLegend__list li:first-child > div {
  border-bottom-left-radius: 3px;
}

.SpeedLegend__list li:last-child > div {
  border-bottom-right-radius: 3px;
}

.SpeedLegend__list li > div {
  height: 13px;
  font-size: 12px;
}

.SpeedLegend__item {
  flex: 1;
}

.SpeedLegend__units {
  background-color: darkgray;
  padding: 0 8px;
  font-weight: 700;
  text-shadow: 0 0 3px white;
}

.SpeedLegend__color {
  padding-left: 8px;
  font-weight: 500;
  text-shadow: 0 0 3px white, 0 0 3px white, 0 0 5px white, 0 0 5px white;
}

.SpeedLegend__color--s0 {
  background-color: #0216c8;
  
}
.SpeedLegend__color--s1 {
  background-color: #4e74fc;
}
.SpeedLegend__color--s2 {
  background-color: #4db3ff;
}
.SpeedLegend__color--s3 {
  background-color: #00e6b8;
}
.SpeedLegend__color--s4 {
  background-color: #b3f95b;
}
.SpeedLegend__color--s5 {
  background-color: #ff0;
}
.SpeedLegend__color--s6 {
  background-color: #ffd525;
}
.SpeedLegend__color--s7 {
  background-color: #ff8717;
}
.SpeedLegend__color--s8 {
  background-color: red;
}
