.TimeLine {
  padding: 3px 5px;
  min-width: 300px;
}

.TimeLine__main {
  padding: 9px 0 5px;
}

.TimeLine__line {
  position: relative;
  height: 2px;
  background-color: rgba(155, 155, 155, 0.5);
  box-shadow: 0 -2px 0 0 rgba(155, 155, 155, 0.5);
}

.TimeLine__point {
  position: absolute;
  border-radius: 50%;
  background-color: orange;
  height: 20px;
  width: 20px;
  left: -2px;
  top: -10px;
  z-index: 20;
  cursor: pointer;
}

.TimeLine__progress {
  position: absolute;
  height: 4px;
  background-color: cornflowerblue;
  z-index: 10;
  left: 0;
  top: -2px;
 }
