.RateControl {
  display: flex;
  user-select: none;
}

.RateControl__option {
  font-size: 13.3px;
}

.RateControl__option:not(:last-child) {
  margin-right: 12px;
}

.RateControl__option label {
  position: relative;
  top: -3px;
  text-shadow: 0 0 3px white, 0 0 3px white, 0 0 5px white, 0 0 5px white;
}
