.ControlGroup {}

.ControlGroup__btn {
  width: 30px;
  height: 25px;
  clear: none;
  cursor: pointer;
}

.ControlGroup__btn img {
  height: 19px;
}

.ControlGroup__btn--active {
  background-color: rgba(255, 165, 0, 0.2);
}
